<template>
	<ion-page id="newsdetail">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button text="Zurück" default-href="/"></ion-back-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="inset-container" :fullscreen="true">
			<div class="postwrap">
			<div class="content ion-padding">
				<h1 class="slabs">{{ uber.title }}</h1>
				<ion-text v-html="uber.content"></ion-text>
			</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonButtons,
	IonContent,
	IonHeader,
	IonBackButton,
	IonPage,
	IonText,
	IonToolbar,
} from '@ionic/vue';

export default {
	name: 'News',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonBackButton,
		IonPage,
		IonText,
		IonToolbar,
	},
	data() {
		return {
			uber: {},
		};
	},
	methods: {
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				`/items/uber/${this.$route.params.id}?fields=*`
			);
			this.uber = data.data;
		},
	},
};
</script>

<style lang="scss" scoped>
#newsdetail {
	.postwrap {
		max-width: 960px;
		margin: 0 auto;
		background-color: var(--ion-color-light);
	}
	.content {
		h1 {
			margin-top: 5px;
			font-size: 24px;
		}
		span {
			color: var(--ion-color-dark);
			font-size: 12px;
		}
		.advertorial {
			background-color: var(--ion-color-tertiary);
			display: inline !important;
			padding-left: 2px;
			padding-right: 2px;
		}
	}
}
</style>
